import React, { useEffect, useState } from "react";
import { Table, Tabs } from "antd";
import { ColumnsType } from "antd/es/table";
import { getClass } from "../helper";
import { AppPagination } from "../../../../components";
import { usePagination } from "../../../../hooks";
import { documentService } from "../../../../api/document";
import {
  COPILOTS_NAME,
  getLocalDateTimeString,
  getOffsetFromPageAndPageSize,
} from "../../../../utils";
import "./expanded-row-content.scss";
import { CLAIMS_PAGE_DATA, CLAIMS_PAGE_STATUSES } from "../constants";

const { TabPane } = Tabs;

interface IPageDataType {
  key: string;
  pageNumber: number;
  updatedAt: string;
  status: string;
  message: string;
  medicalEntities: string;
}

// interface IDocumentsDataType extends IDocument {
//   key: string;
//   name: string;
//   createdAt: string;
//   updatedAt: string;
//   status: string;
//   action: string;
//   pageStatus: string;
// }

const getColumnsStatus = (): ColumnsType<IPageDataType> => [
  {
    title: "Page No.",
    dataIndex: "pageNumber",
    fixed: "left",
    className: "expandedContent-pageStatusTable-pageNo",
  },
  {
    title: "Process",
    dataIndex: "message",
    key: "process",
    className: "expandedContent-pageStatusTable-message-process",
    render: (message) => (
      <div>
        {message &&
          message.map((msg: any, index: any) => (
            <div key={index} className="expandedContent-pageStatusTable-message-content">
              {msg.Step}
            </div>
          ))}
      </div>
    ),
  },
  {
    title: "Start Time",
    dataIndex: "message",
    key: "startTime",
    className: "expandedContent-pageStatusTable-message-startTime",
    render: (message) => (
      <div>
        {message &&
          message.map((msg: any, index: any) => (
            <div key={index} className="expandedContent-pageStatusTable-message-content">
              {getLocalDateTimeString(msg.StartTime)}
            </div>
          ))}
      </div>
    ),
  },
  {
    title: "End Time",
    dataIndex: "message",
    key: "endTime",
    className: "expandedContent-pageStatusTable-message-endTime",
    render: (message) => (
      <div>
        {message &&
          message.map((msg: any, index: any) => (
            <div key={index} className="expandedContent-pageStatusTable-message-content">
              {getLocalDateTimeString(msg.EndTime)}
            </div>
          ))}
      </div>
    ),
  },
  {
    title: "Updated At",
    dataIndex: "updatedAt",
    className: "expandedContent-pageStatusTable-time",
  },
  {
    title: "Status",
    dataIndex: "status",
    className: "expandedContent-pageStatusTable-status",
    render: (text, value) => (
      <div>
        <div className="status" style={{ color: getClass(value.status.replace(/-/g, " ")) }}>
          <p style={{ color: getClass(value.status) }}>{value.status.replace(/-/g, " ")}</p>{" "}
        </div>
      </div>
    ),
  },
];

const getColumnsLogs = [
  {
    title: "Process",
    dataIndex: "Process",
    key: "Process",
    className: "expandedContent-logsTable-Process",
  },
  {
    title: "Start Time",
    dataIndex: "StartTime",
    key: "StartTime",
    className: "expandedContent-logsTable-time",
  },
  {
    title: "End Time",
    dataIndex: "EndTime",
    key: "EndTime",
    className: "expandedContent-logsTable-time",
  },
];

interface Props {
  record: IDocumentsDataType;
  expandedRowKey: any;
  copilotName: any
}

const ExpandedRowContent = (props: Props) => {
  const { record, expandedRowKey, copilotName } = props;
  const { onPaginationChange, page, pageSize, pageSizeOptions } = usePagination();
  const [pagesData, setPagesData] = useState<any[]>([]);
  const [logsData, setLogsData] = useState<any[]>([]);
  const [isLoadingLogs, setIsLoadingLogs] = useState(false);
  const [total, setTotal] = useState(record.TotalPages);
  const tabTitle =
    copilotName === "CCA" || copilotName === "A&G Insights" ? "Request Status" : "File Status";

  useEffect(() => {
    const fetchPagesData = async () => {
      try {
        setIsLoadingLogs(true);
        let data ;
        if(copilotName===COPILOTS_NAME.CLAIMS){
          data = CLAIMS_PAGE_DATA
        }
        else if(copilotName===COPILOTS_NAME.PCP){
          const response:any = await documentService.getPcpPagesHistory(String(record.Id));
          data = response;

        }else{
          const response = await documentService.getDocumentPages(
            record.Id,
            pageSize,
            getOffsetFromPageAndPageSize(page, pageSize)
          );
          data = response.data;
        }
        let pageStatusData
        if([COPILOTS_NAME.PCP,COPILOTS_NAME.CLAIMS].includes(copilotName)){
          pageStatusData = data.data.map((pageItem: any) => ({
            key: copilotName === COPILOTS_NAME.CLAIMS
            ? pageItem.ClaimDocumentId.toString()
            : pageItem.PCPDocumentId.toString(),            
            pageNumber: pageItem.PageNumber,
            updatedAt: getLocalDateTimeString(pageItem.UpdatedAt),
            status: pageItem.Status,
            message: pageItem.historyStats,
            medicalEntities: "",
          }));
        }else{
          pageStatusData = data.data.map((pageItem: any) => ({
            key: pageItem.DocumentId.toString(),
            pageNumber: pageItem.Page,
            updatedAt: getLocalDateTimeString(pageItem.UpdatedAt),
            status: pageItem.Status,
            message: pageItem.historyStats,
            medicalEntities: "",
          }));
        }
        setPagesData(pageStatusData);
        setTotal(data.pagination?.totalCount ? data.pagination.totalCount : total);
      } catch (error) {
        // Handle error
      } finally {
        setIsLoadingLogs(false);
      }
    };

    if (
      (([COPILOTS_NAME.HEDIS_INSIGHTS, COPILOTS_NAME.PCP, COPILOTS_NAME.CLAIMS].includes(copilotName) )&& expandedRowKey)
    ) {
      if (record) {
        fetchPagesData();
      }
    }
  }, [expandedRowKey, page, pageSize, record]);

  useEffect(() => {
    const fetchLogsData = async () => {
      try {
        setIsLoadingLogs(true);
        let statuses;
        switch (copilotName) {
          case COPILOTS_NAME.CLAIMS:
            statuses = CLAIMS_PAGE_STATUSES;
            break;

          case COPILOTS_NAME.PEGA_PRIOR_AUTH:
            statuses = await documentService.getPegaStats(record.DocumentUUID);
            break;
          
          case COPILOTS_NAME.CCA:
          case COPILOTS_NAME.ANG_INSIGHTS:
          case COPILOTS_NAME.UM:
            statuses = await documentService.getAngCCAStats(String(record.id));
            break;
          
          case COPILOTS_NAME.PCP:
            statuses = await documentService.getPcpDocumentsHistory(String(record.Id));
            break;
          
          default:
            statuses = await documentService.getHedisStats(record.Id);
            break;
        }
        const processLogsData = statuses.map((item: any) => ({
          key: item.DocumentUUID,
          ...item,
          StartTime: getLocalDateTimeString(item.StartTime),
          EndTime: getLocalDateTimeString(item.EndTime),
        }));
        const requestsLogsData = statuses.map((item: any) => ({
          key: item.id,
          Process: item.status,
          StartTime: getLocalDateTimeString(item.createdAt),
          EndTime: getLocalDateTimeString(item.updatedAt),
        }));
        const hedisOrPcpRequestsLogsData = statuses.map((item: any) => ({
          key: item.Id,
          Process: item.Status,
          StartTime: getLocalDateTimeString(item.CreatedAt),
          EndTime: getLocalDateTimeString(item.UpdatedAt),
        }));
        
        let tempLogsData;

        switch (copilotName) {
          case COPILOTS_NAME.CCA:
          case COPILOTS_NAME.ANG_INSIGHTS:
          case COPILOTS_NAME.UM:
            tempLogsData = requestsLogsData;
            break;
          case COPILOTS_NAME.HEDIS_INSIGHTS:
          case COPILOTS_NAME.PCP:
          case COPILOTS_NAME.CLAIMS:
            tempLogsData = hedisOrPcpRequestsLogsData;
            break;
          default:
            tempLogsData = processLogsData;
        }

        setLogsData(tempLogsData);

      } catch (error) {
        // Handle error
      } finally {
        setIsLoadingLogs(false);
      }
    };

    if (expandedRowKey) {
      if (record) {
        fetchLogsData();
      }
    }
  }, [expandedRowKey]);

  return (
    <div className="expandedContent">
      <h3 className="expandedContent-title">Detailed Logs</h3>
      <Tabs defaultActiveKey="1">
        <TabPane tab={tabTitle} key="1">
          <Table
            loading={isLoadingLogs}
            rowKey={record.DocumentUUID}
            columns={getColumnsLogs}
            dataSource={logsData}
            pagination={false}
          />
        </TabPane>
        {([COPILOTS_NAME.HEDIS_INSIGHTS, COPILOTS_NAME.PCP, COPILOTS_NAME.CLAIMS].includes(copilotName)) && (
          <TabPane tab="Page Level Status" key="2">
            <AppPagination
              className="tl m-b paginationDiv"
              {...{
                showSizeChanger: true,
                current: page,
                total: total,
                pageSize,
                pageSizeOptions,
                onChange: onPaginationChange,
              }}
            />
            <Table
              loading={isLoadingLogs}
              rowKey={record.DocumentUUID}
              dataSource={pagesData}
              columns={getColumnsStatus()}
              pagination={false}
              bordered
            />
          </TabPane>
        )}
      </Tabs>
    </div>
  );
};

export default ExpandedRowContent;
