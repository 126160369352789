  import React, { useEffect, useMemo, useState } from "react";
  import { Button, Empty, Input, Table } from "antd";
  import { useFetchDocuments } from "../../../../hooks";
  import { getLocalDateTimeString, MODAL_TYPE, openNotificationWithIcon } from "../../../../utils";
  import { PlusCircleOutlined } from "@ant-design/icons";
  import { ClaimsService } from "../../../../api/claims";
  import { useDispatch, useSelector } from "react-redux";
  import { openModal, RootState } from "../../../../store";
  import { Loading } from "../../../../components";

  interface ClaimSetting {
      key: string;
      name: string;
      value: string;
      createdAt: string;
      updatedAt: string;
  }

  interface ColumnType {
      title: string;
      dataIndex?: string;
      key: string;
      className?: string;
      render?: (text: string, record: ClaimSetting) => JSX.Element | string;
  }

const ClaimsSettings = () => {
  const { fetchClaimsSettings, claimsSettings, isLoading } = useFetchDocuments();
  const [editingKey, setEditingKey] = useState<string | null>(null);
  const [value,setValue] = useState<string>("");
  const [trigger,setTrigger] = useState<boolean>(false);
  const dispatch = useDispatch()
  const {isChanged} = useSelector((state: RootState) => state.ui); 
  useEffect(() => {
    fetchClaimsSettings();
  }, [trigger,isChanged]);

    const edit = (key: string) => {
      const valueResult:any = claimsSettings.find((item:any) => item.uuid === key)
      setValue(valueResult?.value)
      setEditingKey(key);
    };

    const cancel = () => {
      setEditingKey(null);
    };

  const save = async (key: string) => {
      if (value.trim().length === 0) {
          return openNotificationWithIcon("", "Value cannot be empty", "error");
      }
      try {
          const result = await ClaimsService.updateClaimsSettings(key, value);
          if (result.status === 200) {
              setTrigger(!trigger);
              openNotificationWithIcon("", "Claim setting updated successfully", "success");
          }
      } catch (error) {
          openNotificationWithIcon("", "Failed to update claim setting", "error");
      } finally {
          setEditingKey(null);
      }
  };

  const create = async() => {
      dispatch(openModal(MODAL_TYPE.CLAIMS_SETTINGS));
  }

    const dataSource = useMemo(
      () =>
        claimsSettings.map((document: any) => ({
          key: document.uuid, 
          name: document.name,
          value: document.value,
          createdAt: getLocalDateTimeString(document.createdAt),
          updatedAt: getLocalDateTimeString(document.updatedAt),
        })),
      [claimsSettings]
    );

  const getColumns = (): ColumnType[] => [
      {
          title: "Name",
          dataIndex: "name",
          key: "name",
          className: "text-wrap-class",
      },
      {
          title: "Value",
          dataIndex: "value",
          key: "value",
          render: (text, record) =>
              editingKey === record.key ? <Input defaultValue={text} onChange={(e) => setValue(e.target.value)}/> : text,
      },
      {
          title: "Created At",
          dataIndex: "createdAt",
          key: "createdAt",
      },
      {
          title: "Updated At",
          dataIndex: "updatedAt",
          key: "updatedAt",
      },
        {
          title: "Actions",
          key: "actions",
          render: (_, record) => {
            const editable = editingKey === record.key;
            return editable ? (
              <div style={{ display: "flex", flexDirection: "column" , gap:'10px' }}>
                <Button onClick={() => save(record.name)}>Save</Button>
                <Button onClick={() => cancel()}>Cancel</Button>
              </div>
            ) : (
              <Button onClick={() => edit(record.key)}>Edit</Button>
            );
          },
        },
  ];

    return (
      <div>
          <div style={{ display: "flex", justifyContent: "flex-end", marginRight: "10px", fontSize: "25px" , cursor:'pointer'}}>
            <Button onClick={create}>Add New Settings <PlusCircleOutlined title="Add"/></Button> 
          </div>
        <div
          style={{ display: "flex", marginBottom: "2em" }}
          className="tabHeader"
        ></div>
        <Table
          className="document-container-table"
          columns={getColumns()}
          dataSource={dataSource}
          loading={isLoading}
          pagination={false}
          locale={{ emptyText: <Empty>No Data Found</Empty> }}
        />
      </div>
    );
  };

  export default ClaimsSettings;
