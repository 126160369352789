import React, { useState } from "react";
import { Modal, Button, Input, Form } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import "../modal.scss";

interface ClaimsSettingsModalProps {
    visibility: boolean;
    handleClose: () => void;
    handleSubmit: (name: string, value: string) => void;
}

const ClaimsSettingsModal: React.FC<ClaimsSettingsModalProps> = ({ 
    visibility, 
    handleClose, 
    handleSubmit 
}) => {
    const [name, setName] = useState<string>('');
    const [value, setValue] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    const handleModalClose = () => {
        setName('');
        setValue('');
        setLoading(false);
        handleClose();
    };

    const handleFormSubmit = async () => {
        setLoading(true);
        try {
            await handleSubmit(name, value);
            handleModalClose();
        } catch (error) {
            console.error("Failed to submit form", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            className="autonomizeModal"
            centered
            width={587}
            title="Claims Settings"
            open={visibility}
            footer={null}
            closeIcon={<CloseOutlined onClick={handleModalClose} />}
        >
            <h1 className="m-b">Claims Settings</h1>
            <Form layout="vertical" className="flex flex-column gp">
                <Form.Item label="Name" required>
                    <Input 
                        value={name} 
                        onChange={(e) => setName(e.target.value)} 
                        placeholder="Enter name" 
                    />
                </Form.Item>
                <Form.Item label="Value" required>
                    <Input 
                        value={value} 
                        onChange={(e) => setValue(e.target.value)} 
                        placeholder="Enter value" 
                    />
                </Form.Item>
                <div className="flex jcc gp">
                    <Button type="primary" className="outline" onClick={handleModalClose}>
                        Cancel
                    </Button>
                    <Button 
                        type="primary" 
                        className="fill"
                        disabled={!name.trim() || !value.trim()} 
                        loading={loading} 
                        onClick={handleFormSubmit}
                    >
                        {loading ? 'Submitting...' : 'Submit'}
                    </Button>
                </div>
            </Form>
        </Modal>
    );
};

export default ClaimsSettingsModal;