import { Entities, Trait } from '../../../api';
import { SEARCH_KEYWORD_DELIMINATOR, scoreToPercentage } from '../../../utils';

interface KeyValueMap {
  [key: string]: string;
}
export const CATEGORY_COLOR_MAPPING: KeyValueMap = {
  MEDICAL_CONDITION: '#6077E1',
  MEDICATION: '#00CACD',
  TEST_TREATMENT_PROCEDURE: '#9B67C6',
  PROTECTED_HEALTH_INFORMATION: '#95CB7A',
  ANATOMY: '#E8AE3D',
};

export enum INSIGHT_TYPE {
  MEDICAL_CONDITION = 'MEDICAL_CONDITION',
  MEDICATION = 'MEDICATION',
  TEST_TREATMENT_PROCEDURE = 'TEST_TREATMENT_PROCEDURE',
}

const CATEGORY_PRIORITY_ORDER = [
  'TEST_TREATMENT_PROCEDURE',
  'MEDICAL_CONDITION',
  'ANATOMY',
];

const CATEGORY_TYPE_TO_KEYWORD_MAPPING: KeyValueMap = {
  DX_NAME: 'DISEASE',
  PROCEDURE_NAME: 'PROCEDURE',
  TEST_NAME: 'TEST',
  TREATMENT_NAME: 'TREATMENT',
  PHONE_OR_FAX: 'CONTACT',
  SYSTEM_ORGAN_SITE: 'ANATOMY',
};

const checkTraitScoreAndEntityScore = (entity: Entities): Trait => {
  const trait = {
    Name: entity.Type,
    Score: entity.Score,
  };
  if (entity.Traits.length > 0) {
    const traitEntity = entity.Traits.reduce((prevValue, currValue) =>
      currValue.Score > prevValue.Score ? currValue : prevValue,
    );
    trait.Name = traitEntity.Name;
    trait.Score = traitEntity.Score;
  }
  return trait;
};

const addAttributesInEntities = (entities: Entities[]) => {
  const res: Entities[] = [];
  entities.map((entity) => {
    const entityIndex = res.findIndex((item) => item.Id === entity.Id);
    if (entityIndex === -1) res.push(entity);
    if (entity.Attributes) {
      entity.Attributes.map((attribute) => {
        const attributeIndex = res.findIndex(
          (item) => item.Id === attribute.Id,
        );
        if (attributeIndex === -1) {
          res.push(attribute);
        }
      });
    }
  });
  return res.sort((a, b) => a.BeginOffset - b.BeginOffset);
};

const getFilteredEntities = (
  entities: Entities[],
  selectedLegends: string[],
): Entities[] => {
  const medicalEntities = addAttributesInEntities(entities);
  const removedMedicalEntities = [] as Entities[];
  medicalEntities.forEach((entity) => {
    const startOffset = entity.BeginOffset;
    const endOffset = entity.EndOffset;
    medicalEntities.forEach((item) => {
      if (
        item.BeginOffset >= startOffset &&
        item.EndOffset <= endOffset &&
        entity.Id !== item.Id
      ) {
        const isEntityIncludedInLegends = selectedLegends.includes(
          entity.Category,
        );
        const isItemIncludedInLegends = selectedLegends.includes(item.Category);
        if (isItemIncludedInLegends && !isEntityIncludedInLegends)
          removedMedicalEntities.push(entity);
        else if (!isItemIncludedInLegends && isEntityIncludedInLegends)
          removedMedicalEntities.push(item);
        else {
          const entityPriorityIndex = CATEGORY_PRIORITY_ORDER.findIndex(
            (e) => e === entity.Category,
          );
          const itemPriorityIndex = CATEGORY_PRIORITY_ORDER.findIndex(
            (e) => e === item.Category,
          );

          if (itemPriorityIndex !== -1 && entityPriorityIndex !== -1) {
            itemPriorityIndex < entityPriorityIndex
              ? removedMedicalEntities.push(entity)
              : removedMedicalEntities.push(item);
          } else {
            itemPriorityIndex === -1
              ? removedMedicalEntities.push(item)
              : removedMedicalEntities.push(entity);
          }
        }
      }
    });
  });
  const filteredEntities = medicalEntities.filter(
    (el) => removedMedicalEntities.indexOf(el) < 0,
  );
  return filteredEntities;
};

export const getEvidenceViewerMedicalEntitiesText = (
  content: string,
  medicalEntities: Entities[],
  searchKeywords: string,
  selectedLegends: string[],
) => {
  if (medicalEntities && medicalEntities.length > 0) {
    const entities = getFilteredEntities(medicalEntities, selectedLegends);
    let offsetPositionChange = 0;
    entities.forEach((entity) => {
      const { Name, Score } = checkTraitScoreAndEntityScore(entity);
      if (selectedLegends.includes(entity.Category)) {
        const beginOffset = entity.BeginOffset + offsetPositionChange;
        const color = CATEGORY_COLOR_MAPPING[entity.Category];
        const startHtml = `<span style='background-color:${color};color:#fff;font-size:14px;margin:5px;border-radius:4px; padding:5px;' title='Score: ${scoreToPercentage(
          Score,
        )}'>`;
        const endHtml = `<span style='font-family:Font-Bold;font-size:12px;padding:3px;margin:0 5px;color:${color};border-radius:4px;background-color:#fff;'>${
          CATEGORY_TYPE_TO_KEYWORD_MAPPING[Name]
            ? CATEGORY_TYPE_TO_KEYWORD_MAPPING[Name]
            : Name
        }</span></span>`;
        content = [
          content.slice(0, beginOffset),
          startHtml,
          content.slice(beginOffset),
        ].join('');
        offsetPositionChange += startHtml.length;
        const endOffset = entity.EndOffset + offsetPositionChange;
        content = [
          content.slice(0, endOffset),
          endHtml,
          content.slice(endOffset),
        ].join('');
        offsetPositionChange += endHtml.length;
      }
    });
  }
  if (content) {
    content = `<p style="line-height: 2.2;font-family:Font-Regular;font-size:14px;">${content.replace(
      /(?:\r\n|\r|\n)/g,
      '<br/>',
    )}</p>`;
  }

  if (searchKeywords && searchKeywords.length > 0) {
    searchKeywords.split(SEARCH_KEYWORD_DELIMINATOR).forEach((searchStr) => {
      const html = `<span style='border:3px solid #000;margin:5px;border-radius:6px; padding:4px 6px;'>${searchStr}</span>`;
      const expression = searchStr
        .replace(/(?:\r\n|\r|\n)/g, '<br/>')
        .replace(/[-[\]{}()*+?.,\\^$|#]/g, '\\$&');
      content = content.replaceAll(new RegExp(expression, 'gi'), html);
    });
  }
  return content;
};
