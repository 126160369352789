import { Dispatch } from "redux";
import { connect } from "react-redux";
import ClaimsSettingsModal from "./ClaimsSettingsModal";
import { closeModal, RootState, setIsChanged } from "../../../store";
import { MODAL_TYPE, openNotificationWithIcon } from "../../../utils";
import { ClaimsService } from "../../../api/claims";


type StateProps = {
    visibility: boolean;
};
type DispatchProps = {
    handleClose: () => void;
    handleSubmit: (name: string, value: string) => void;
};
export type ClaimsSettingsModalProps = StateProps & DispatchProps;

const handleCreateSettings = async (name: string, value: string) => {
    try {
        const result = await ClaimsService.createClaimsSettings(name, value);
        if (result.status === 201) {
            openNotificationWithIcon("", "Claim setting created successfully", "success");
        }
    } catch (error) {
        openNotificationWithIcon("", "Failed to create claim setting", "error");
    } 
}

const mapStateToProps = (state: RootState): StateProps => ({ 
    visibility: state.ui.modal[MODAL_TYPE.CLAIMS_SETTINGS],
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    handleClose: () => {
        dispatch(closeModal(MODAL_TYPE.CLAIMS_SETTINGS));
    },
    handleSubmit: async(name: string, value: string) => {
        await handleCreateSettings(name, value);
        dispatch(setIsChanged(true));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(ClaimsSettingsModal);