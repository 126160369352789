import React, { useEffect, useMemo, useState } from "react";
import { Table, Button, Empty, Input, Tag } from "antd";
import { ColumnsType, TableProps } from "antd/es/table";
import { useFetchDocuments } from "../../../../hooks";
import { getLocalDateTimeString, MODAL_TYPE, openNotificationWithIcon } from "../../../../utils";
import { documentService } from "../../../../api/document";
import { useDispatch, useSelector } from "react-redux";
import { AppPagination, Loading } from "../../../../components";
import { EditOutlined, ReloadOutlined, SearchOutlined, SyncOutlined } from "@ant-design/icons";
import {
  clearModalData,
  openModal,
  RootState,
  setIsChanged,
  setModalData,
} from "../../../../store";
import "./../copilots-details.scss";
import { ClaimsService } from "../../../../api/claims";
import Search from "antd/es/input/Search";

export interface IDocumentDataType {
  key: string;
  name: string;
  uuid: string;
  status: string;
  eTag: string;
  idFromSource: string;
  metadata: any;
  updatedAt: string;
  createdAt: string;
  editCodes: { id: number; edit_code: number }[];
}

interface Props {
  copilotName: string;
}

const DocumentsTable = ({ copilotName }: Props) => {
  const {
    documents,
    isDocumentsLoading,
    paginationObj,
    fetchClaimsDocuments,
    totalDocuments,
    setDocuments,
    searchTerm,
    setSearchTerm,
    setSortBy,
  } = useFetchDocuments();  
  const { onPaginationChange, page, pageSize, pageSizeOptions } = paginationObj;
  const dispatch = useDispatch();
  const [sorterState, setSorterState] = useState() as any;
  const { isChanged, data } = useSelector((state: RootState) => state.ui);
  const isFileImported = useSelector((state: RootState) => state.ui.isFileImported);
  const [refreshLoading, setRefreshLoading] = useState(false);
  
  const onTableChange: TableProps<IDocumentDataType>["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {



    setSorterState(sorter); // Keep track of the sorter state
  
    const firstSorter = Array.isArray(sorter) ? sorter[0] : sorter;
  
    if (firstSorter && firstSorter.field && firstSorter.order) {
      let field = firstSorter.field; // Use the field directly
      if (field === "uuid") field = "documentUuid"; 
       
      const order = firstSorter.order; // Get the order (ascend/descend)
  
      // Set sortBy directly based on field and order
      switch (order) {
        case "descend":
          setSortBy(`${field}:desc`); // Format: field:desc
          break;
        case "ascend":
          setSortBy(`${field}:asc`); // Format: field:asc
          break;
        default:
          setSortBy(""); // Clear sortBy when no sorting is applied
          break;
      }
    }
  };
  
  
  
  
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);
  };

  const handleSearch = async () => {
    await fetchClaimsDocuments();
  };

  const dataSource: IDocumentDataType[] = useMemo(
    () =>
      documents.map((document: any) => ({
        key: document.documentUuid,
        name: document.name,
        uuid: document.documentUuid,
        status: document.documentStatusId,
        eTag: document.eTag,
        idFromSource: document.idFromSource,
        metadata: JSON.parse(document.metadata),
        updatedAt: getLocalDateTimeString(document.updatedAt),
        createdAt: getLocalDateTimeString(document.createdAt),
        editCodes: document.editCodes || [],
      })),
    [documents]
  );

  const handleAction = (record: IDocumentDataType) => {
    dispatch(setModalData(record));
    dispatch(openModal(MODAL_TYPE.CLAIMS_DOCUMENT_EDIT));
  };

  const handleRefresh = async () => {
    setRefreshLoading(true);
    try {
      await fetchClaimsDocuments();
    } catch (error) {
      openNotificationWithIcon("Failed to refresh", "Failed to refresh data", "error");
    } finally {
      setRefreshLoading(false);
    }
  };

  const handleDocUpdate = async (documentUuid: string) => {
    try {
      await ClaimsService.updateClaimsDoc(documentUuid);
      openNotificationWithIcon("Updating Document", "Started to update the document", "success");
      fetchClaimsDocuments();
    } catch (error) {
      openNotificationWithIcon("Failed to update", "Failed to update document", "error");
    }
  };

  useEffect(() => {
    if (isChanged) {
      fetchClaimsDocuments();
      dispatch(clearModalData());
      dispatch(setIsChanged(false));
    }
  }, [isChanged,sorterState]);

  const getColumns = (): ColumnsType<IDocumentDataType> => {
    return [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        className: "name text-wrap-class",
        sorter: true,
      },
      {
        title: "UUID",
        dataIndex: "uuid",
        key: "uuid",
        sorter: true,
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        className: "w-10 text-wrap-class",
      },
      {
        title: "Metadata",
        dataIndex: "metadata",
        key: "metadata",
        className: "metadata-cell text-wrap-class",
        render: (metadata: IDocumentDataType["metadata"]) =>
          metadata.is_new_version_available ? (
            <Tag color="blue" bordered={false} icon={<SyncOutlined spin />}>
              Update Available
            </Tag>
          ) : (
            <Tag bordered={false} color="default">
              No updates available
            </Tag>
          ),
      },
      {
        title: "Edit Codes",
        dataIndex: "editCodes",
        key: "editCodes",
        className: "text-wrap-class",
        render: (editCodes: IDocumentDataType["editCodes"]) => (
          <div className="edit-codes-container">
            {editCodes.length > 0 ? (
              editCodes.map((code, index) => (
                <Tag key={index} className="edit-code-tag">
                  {code.edit_code}
                </Tag>
              ))
            ) : (
              <span className="no-edit-codes">No edit codes</span>
            )}
          </div>
        ),
        
      },
      {
        title: "Updated At",
        dataIndex: "updatedAt",
        key: "updatedAt",
        className: "w-10 text-wrap-class",
        sorter: true,
      },
      {
        title: "Created At",
        dataIndex: "createdAt",
        key: "createdAt",
        className: "w-10 text-wrap-class",
        sorter: true,
      },
      {
        title: "Actions",
        key: "actions",
        render: (_, record) => (
          <div className="flex gp-10 aic jcc">
            <Button
              type="default"
              icon={<EditOutlined />}
              className="link-color cursor-pointer action-button"
              onClick={() => handleAction(record)}
            >
              Edit
            </Button>
            {record.metadata.is_new_version_available && (
              <Button
                onClick={() => handleDocUpdate(record.uuid)}
                type="primary"
                icon={<SyncOutlined />}
                className="action-button"
                style={{
                  backgroundColor: "#1890ff",
                  borderColor: "#1890ff",
                  color: "white",
                }}
              >
                Update
              </Button>
            )}
          </div>
        ),
      },
    ];
  };

  useEffect(() => {
    if(searchTerm === ""){
        fetchClaimsDocuments();
    }
  }, [fetchClaimsDocuments, isFileImported, searchTerm]);

  return (
    <div>
      <div className="flex jcsb">
        <div className="flex aic jcc gp-10 search-container-wrapper">
          <div className="search-box-wrapper">
          <Search
            className="record-search m-b"
            placeholder="Search Document"
            enterButton={
              isDocumentsLoading ? <Loading tip="" /> : <Button icon={<SearchOutlined />} />
            }
            value={searchTerm}
            onChange={(e) => handleChange(e)}
            onSearch={(e) => handleSearch()}
            disabled={isDocumentsLoading}
            style={{ width: "20em", marginRight: "1em" }}
            />
            </div>
          <Button className="outline" type="default" onClick={() => handleRefresh()}>
            Refresh <ReloadOutlined spin={refreshLoading} />
          </Button>
          <div style={{ flex: 1 }}></div>
   
        </div>
        <AppPagination
          className="tr m-b paginationDiv"
          {...{
            showSizeChanger: true,
            current: page,
            total: totalDocuments,
            pageSize,
            pageSizeOptions,
            onChange: onPaginationChange,
          }}
        />
      </div>

      <div className="total-count-container">
        <span>Total Records : </span>
        <span>{totalDocuments}</span>
      </div>

      <Table
        className="document-container-table"
        columns={getColumns()}
        dataSource={dataSource}
        onChange={onTableChange}
        loading={isDocumentsLoading}
        pagination={false}
        locale={{ emptyText: <Empty>No Documents Found</Empty> }}
      />
    </div>
  );  
};

export default DocumentsTable;
