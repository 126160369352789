import React, { useState } from "react";
import { Modal, Upload, Checkbox, Button, message, Tag } from "antd";
import { InboxOutlined, CloseOutlined, FileExcelOutlined } from "@ant-design/icons";
import { UploadProps } from "antd/es/upload";
import "../modal.scss";
import { MODAL_TYPE, NotificationType, openNotificationWithIcon } from "../../../utils";
import { RootState, setIsFileImported } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { ClaimsService } from "../../../api/claims";

const { Dragger } = Upload;

interface FileUploadModalProps {
  visibility: boolean;
  handleClose: () => void;
}

const FileUploadModal: React.FC<FileUploadModalProps> = ({ 
  visibility, 
  handleClose, 
   
}) => {
  const [cleanExistingData, setCleanExistingData] = useState(false);
  const [fileList, setFileList] = useState<any[]>([]);
  const [filePath, setFilePath] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [fileToUpload, setFileToUpload] = useState<File | null>(null);
  const dispatch = useDispatch();
  const { modal } = useSelector((state: RootState) => state.ui);

  const uploadProps: UploadProps = {
    name: 'file',
    multiple: false,
    maxCount: 1,
    accept: '.csv,text/csv,application/vnd.ms-excel,application/csv',
    beforeUpload: (file) => {
      const isCSV = file.type === 'text/csv' || 
        file.name.toLowerCase().endsWith('.csv') ||
        file.type === 'application/vnd.ms-excel' ||
        file.type === 'application/csv';

      if (!isCSV) {
        message.error(`${file.name} is not a CSV file`);
        return Upload.LIST_IGNORE;
      }
      
      setFilePath(file.name);
      setFileToUpload(file);
      return false; 
    },
    onChange(info) {
      const files = [...info.fileList];
      setFileList(files);
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  const handleModalClose = () => {
    setFileList([]);
    setFilePath('');
    setFileToUpload(null);
    setLoading(false);
    handleClose();
  };

  const handleUpload = async (file: any) => {
    try {
      const uploadUrl: any = await ClaimsService.getUploadSignedUrl(filePath);
      await ClaimsService.processUploadedFile(uploadUrl.signedUrl, filePath, file);
      let isEditCodeDocMap = false;
      if (modal[MODAL_TYPE.IMPORT_EDIT_CODE_MAPPING]) {
        isEditCodeDocMap = true;
      }
      const res = await ClaimsService.importCSVFileFromBlob(filePath, cleanExistingData, isEditCodeDocMap);
      
      return res;
    } catch (error) {
      console.error('Error uploading file:', error);
      throw error;
    }
  }

  const handleConfirmUpload = async () => {
    if (filePath && fileToUpload) {
      setLoading(true);
      try {
        const res: any = await handleUpload(fileToUpload);
        const { processedRecords } = res.data;
        const { details } = res.data;
        let notificationMessage = "";
        if(processedRecords && ! details){
          notificationMessage = `Successfully inserted ${processedRecords} entries.`;
        }
        if(details){
          if (details.mappingSavedSuccessfullyCount > 0) {
            notificationMessage = `${details.mappingSavedSuccessfullyCount} entries were successfully inserted.`;
          } else {
            notificationMessage += "No entries were created.";
          }

          if (details?.mappingAlreadyExistCount > 0) {
            notificationMessage += `\n${details.mappingAlreadyExistCount} mappings already exist.`;
          }
          
          if (details?.documentNotFoundCount > 0) {
            notificationMessage += `\n${details.documentNotFoundCount} documents were not found.`;
          }
        }
        // If only mappingSavedSuccessfullyCount > 0, keep as success; otherwise, error
        openNotificationWithIcon("Import Result", notificationMessage, 'info');
        dispatch(setIsFileImported(true));
        handleModalClose();
      } catch (error: any) {
        openNotificationWithIcon("Failed to import file", error.response?.data?.message, "error");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Modal
      className="autonomizeModal"
      centered
      width={587}
      title="CSV File Upload"
      open={visibility}
      footer={null}
      closeIcon={<CloseOutlined onClick={handleModalClose} />}
    >
      <h1 className="m-b">Upload CSV File</h1>
      <div className="file-upload-content flex flex-column gp">
        <Dragger 
          {...uploadProps} 
          fileList={fileList}
        >
          <p className="ant-upload-drag-icon">
            <FileExcelOutlined />
          </p>
          <p className="ant-upload-text">Click or drag CSV file to this area to upload</p>
          <p className="ant-upload-hint">
            Only CSV files are supported. Strictly prohibited from uploading sensitive or unauthorized files.
          </p>
        </Dragger>
        {
          modal[MODAL_TYPE.UPLOAD_CSV_FILE] && (
            <>
              <div className="flex">
                <Tag bordered={false} color="warning" className="flex tag-container">
                  <strong>Note:</strong>&nbsp; 
                  Enabling this option will <strong>&nbsp;delete all existing entries</strong>&nbsp;from the table before importing the new CSV file. 
                  If unchecked, the new data will be&nbsp;<strong>appended</strong>&nbsp;to the existing records.
                </Tag>
              </div>
              
              <div>
                <Checkbox 
                  checked={cleanExistingData}
                  onChange={(e) => setCleanExistingData(e.target.checked)}
                >
                  Clean existing data before uploading
                </Checkbox>
              </div>
            </>
          )
        }
        <div className="flex jcc gp">
          <Button type="primary" className="outline" onClick={handleModalClose}>
            Cancel
          </Button>
          <Button 
            type="primary" 
            className="fill"
            disabled={fileList.length === 0} 
            loading={loading} 
            onClick={handleConfirmUpload}
          >
            {loading ? 'Uploading...' : 'Confirm Upload'}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default FileUploadModal;