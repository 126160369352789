import axios from "axios";
import { apiClient } from "./apiClient";
import qs from "qs";

export interface GetUploadSignedUrl {
    data: {
      signedUrl: string;
      accessToken?: string;
    };
    status: string;
  }

const getUploadSignedUrl = async (path: string) => {
    const res = await apiClient.get<GetUploadSignedUrl>(
      `claims/storage/blobSignedUrl/upload?path=${path}`
    );
    return res.data;
  };

  const processUploadedFile = async(url: string, filename: string, file: any) => {
    const fileData = file.originFileObj || file; 
    const res = await axios({
      method: "put",
      url,
      data: fileData,
      headers: {
        "Content-Type": fileData.type,
        "x-ms-blob-type": "BlockBlob",
      },
    });
    return res
  }

  const importCSVFile = async ( cleanRep:boolean ) => {
    const res = await apiClient.post(
      `claims/details/claims-data/import?cleanRepo=${cleanRep}`
    );
    return res.data;
  }

const importCSVFileFromBlob = async ( fileName:string, cleanRep:boolean, isEditCodeDocMap?: boolean) => {
  const res = await apiClient.post(
    `claims/storage/claims-data/import`, {
      fileName, cleanRepo: cleanRep, isEditCodeDocMap
    }
  );
  return res;
}

const getClaimsData = async ( limit: number, offset: number ) => {
  const res = await apiClient.get(
    `claims/details/claims-data`, {
      params:{limit, offset}
    }
  );
  return res;
}

const getClaimsDocuments = async ( limit: number, offset: number, searchTerm: string ,sortValue: string) => {
  const res = await apiClient.get(
    `claims/documents`, {
      params:{limit, offset, search: searchTerm, sortBy: sortValue}  
    }
  );
  return res;
}

const updateClaimsDocument = async ( uuid: string, data: any ) => {
  const res = await apiClient.put(
    `claims/documents/update/${uuid}`, data
  );
  return res;
}

const getClaimsSettings = async () => {
  const res = await apiClient.get(
    `claims/settings`,
  );
  return res;
}

const updateClaimsSettings = async (name: string, value: string) => {
  const res = await apiClient.put(
    `claims/settings/update/${name}`, 
    { value },
  );
  return res;
}

const createClaimsSettings = async (name: string, value: string) => {
  const res = await apiClient.post(
    `claims/settings/create`, 
    { name, value },
  );
  return res;
}

const getEditCodeMapping = async ( limit: number, offset: number, searchTerm: string, sortBy: string ) => {
  const res = await apiClient.get(
    `claims/documents/editcode-doc-map`, {
      params:{limit, offset, search: searchTerm, sortBy}
    }
  );
  return res;
}

const saveEditCodeMapping = async (id: number, editCodeValue: string, documentValue: string) => {
  const res = await apiClient.put(`claims/documents/editcode-doc-map/${id}`, {
    edit_code: editCodeValue,
    document: documentValue,
  });
  return res;
};

const deleteEditCodeMapping = async (id: number) => {
  const res = await apiClient.delete(`claims/documents/editcode-doc-map/${id}`);
  return res;
}

const updateClaimsDoc = async (uuid: string) => {
  const res = await apiClient.post(`claims/documents/process/${uuid}`);
  return res;
}

export const ClaimsService = {
    getUploadSignedUrl,
    importCSVFile,
    getClaimsData,
    processUploadedFile,
    importCSVFileFromBlob,
    getClaimsDocuments,
    getClaimsSettings,
    updateClaimsSettings,
    createClaimsSettings,
    updateClaimsDocument,
    getEditCodeMapping,
    saveEditCodeMapping,
    deleteEditCodeMapping,
    updateClaimsDoc
}