import { Dispatch } from "redux";
import { connect } from "react-redux";
import DocumentEditModal from "./EditClaimsDocuments";
import { clearModalData, closeModal, RootState, setIsChanged } from "../../../store";
import { MODAL_TYPE, openNotificationWithIcon } from "../../../utils";
import { ClaimsService } from "../../../api/claims";

type StateProps = {
    visibility: boolean;
    document: Record<string, any>;
    statusOptions: string[];
};

type DispatchProps = {
    handleClose: () => void;
    handleSubmit: (document: Record<string, any>, uuid: string) => void;
};

export type DocumentEditModalProps = StateProps & DispatchProps;

const handleUpdateDocument = async (document: Record<string, any>, uuid: string) => {
    try {
        const response = await ClaimsService.updateClaimsDocument(uuid, document);
        if (response.status === 200) {
            openNotificationWithIcon("success", "Document updated successfully", "success");

        } else {
            throw new Error("Update failed");
        }
    } catch (error) {
        openNotificationWithIcon("error", "Failed to update document", "error");
    }
};

const mapStateToProps = (state: RootState): StateProps => ({
    visibility: state.ui.modal[MODAL_TYPE.CLAIMS_DOCUMENT_EDIT] || false,
    document: state.ui.data || {},
    statusOptions: state.ui.data.statusOptions || [],
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    handleClose: () => {
        dispatch(closeModal(MODAL_TYPE.CLAIMS_DOCUMENT_EDIT));
    },
    handleSubmit: async (document: Record<string, any>, uuid: string) => {
        await handleUpdateDocument(document, uuid);
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentEditModal);
