import React, { useEffect, useMemo, useRef, useState } from "react";
import { useFetchDocuments } from "../../../hooks";
import Table, { ColumnsType, TableProps } from "antd/es/table";
import {
  PAGE_URL,
  getLocalDateTimeString
} from "../../../utils";
import { AppPagination, NotAccessibleFallback } from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import "./requests-logs.scss";
import { useNavigate } from "react-router";
import { Moment } from "moment";
import { DatePicker } from "../../../components";
import { RangeValue } from "rc-picker/lib/interface";
import Search from "antd/lib/input/Search";
import { Button, Select, Empty } from "antd";
import {
  CaretDownFilled,
  CaretUpFilled,
  MinusOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { getClass, rangePresets } from "./helper";
import ExpandedRowContent from "./expanded-row-content/ExpandedRowContent";
import { SorterResult } from "antd/es/table/interface";
import { useParams } from "react-router-dom";
import { RootState, setSelectedDateRange } from "../../../store";
import moment from "moment";
const { Option } = Select;
const { RangePicker } = DatePicker;
// interface IDocumentsDataType extends IDocument {
//   key: number;
//   name: string;
//   createdAt: string;
//   updatedAt: string;
//   status: string;
//   action: string;
//   timeTaken: number;
//   pageStatus?: string;
// }

interface Props {
  copilotName?: string;
}

const Requests = ({ copilotName }: Props) => {
  const {
    documents,
    fetchRequests,
    isDocumentsLoading,
    paginationObj,
    sortFilter,
    totalDocuments,
    isAuthorized,
    defaultFromDate,
    defaultToDate,
    searchTerm,
    setSearchTerm,
    setSortBy,
    setStatus,
  } = useFetchDocuments(copilotName);
  const { onPaginationChange, page, pageSize, pageSizeOptions } = paginationObj;
  const { setSort, sort } = sortFilter;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [expandedRowKey, setExpandedRowKey] = useState<string | null>(null);
  const searchInputRef = useRef(null as any);
  const [sorterState, setSorterState] = useState() as any;
  const [selectedRowKeys, setSelectedRowKeys] = useState<{ key: number; status: string }[]>([]);
  const { copilotId } = useParams();
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<string>("");

  const { fromDate: selectedFromDate, toDate: selectedToDate } = useSelector(
    (state: RootState) => state.ui.selectedDateRange
  );

  const onTableChange: TableProps<IDocumentsDataType>["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {
    if (searchInputRef.current && document.activeElement === searchInputRef.current.input) {
      return;
    }
    setSorterState(sorter);
    const firstSorter = sorter as SorterResult<IDocumentsDataType>;
    switch (firstSorter.order) {
      case "descend":
        setSortBy(`-${firstSorter.field}`);
        break;
      case "ascend":
        setSortBy(firstSorter.field as string);
        break;
      default:
        setSortBy("");
        break;
    }
  };

  const handleExpand = (expanded: boolean, record: IDocumentsDataType) => {
    if (expanded) {
      setExpandedRowKey(record.key);
    } else {
      setExpandedRowKey(null);
    }
  };

  const handleSearch = (value: string) => {
    setSearchTerm(value);
    onPaginationChange(1, pageSize)
  };

  const handleChange = (event: any) => {
    setSearchText(event.target.value);
    if (event.target.value == "") {
      onPaginationChange(1, pageSize)
      setSearchTerm("");
    }
  };

  const handleSort = (column: string | number) => {
    if (!sorterState) {
      setSortBy(`${column}`);
    } else if (sorterState.order === "ascend") {
      setSortBy(`-${column}`);
    } else if (sorterState.order === "descend") {
      setSortBy(``);
    }
  };

  const getSortIcon = (column: string) => {
    let caretUpFilledStyle = { color: "#0000004a" };
    let caretDownFilledStyle = { marginTop: "-0.3em", color: "#0000004a" };
    if (sorterState && sorterState.field === column) {
      if (sorterState.order === "ascend") {
        caretUpFilledStyle = { color: "#000000" };
        caretDownFilledStyle = { marginTop: "-0.3em", color: "#0000004a" };
      } else if (sorterState.order === "descend") {
        caretUpFilledStyle = { color: "#0000004a" };
        caretDownFilledStyle = { marginTop: "-0.3em", color: "#000000" };
      }
    }
    return (
      <>
        <CaretUpFilled style={caretUpFilledStyle} />
        <CaretDownFilled style={caretDownFilledStyle} />
      </>
    );
  };

  const dataSource: IDocumentsDataType[] = useMemo(() => {
    let filteredDocuments = documents;
    if (selectedStatus) {
      filteredDocuments = documents.filter((doc) => doc.status === selectedStatus);
    }
    return filteredDocuments.map((document) => ({
      key: String(document.id),
      name: document.apiEndpoint,
      ...document,
      createdAt: getLocalDateTimeString(document.createdAt),
      updatedAt: getLocalDateTimeString(document.updatedAt),
      timeTaken: Math.abs(document.execution_time / 1000),
      status: document.status,
      action: "",
      pageStatus: "",
      documentId: document.Id
    }));
  }, [documents, selectedStatus]);

  const getColumns = (): ColumnsType<IDocumentsDataType> => {
    const columns = [
      {
        title: (
          <div>
            <div
              onClick={() => handleSort("id")}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              Id
              <div style={{ display: "flex", flexDirection: "column" }}>{getSortIcon("id")}</div>
            </div>
            <Search
              ref={(input) => (searchInputRef.current = input)}
              className="record-search request-container-table-search"
              placeholder="Search id"
              enterButton={
                isDocumentsLoading ? (
                  <Button disabled></Button>
                ) : (
                  <Button icon={<SearchOutlined />} />
                )
              }
              value={searchText}
              onChange={(e) => handleChange(e)}
              onSearch={(e) => handleSearch(e)}
              disabled={isDocumentsLoading}
            />
          </div>
        ),
        dataIndex: "id",
        render: (item: any, record: any) => (
          <p
            className="cc flex cursor-pointer"
            onClick={() => {
              navigate(
                `${PAGE_URL.COPILOTS_DETAILS}/${copilotId}${PAGE_URL.EVIDENCE_VIEWER}?fileId=${record.id}`,
                {
                  state: { data: copilotName },
                }
              );
            }}
          >
            {item}
          </p>
        ),
        key: "id",
        sorter: true,
        className: "request-container-table-id",
        showSorterTooltip: false,
      },
      {
        title: (
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              End Point
              <div style={{ display: "flex", flexDirection: "column" }}></div>
            </div>
          </div>
        ),
        key: "apiEndpoint",
        dataIndex: "apiEndpoint",
        className: "request-container-table-apiEndPoint",
        showSorterTooltip: false,
      },
      {
        title: "Start Time",
        dataIndex: "createdAt",
        className: "request-container-table-startTime",
        sorter: true,
      },
      {
        title: "End Time",
        dataIndex: "updatedAt",
        className: "request-container-table-endTime",
        sorter: true,
      },
      {
        title: "Time Taken(Sec)",
        dataIndex: "timeTaken",
        className: "request-container-table-timeTaken",
        sorter: true,
      },
      {
        title: (
          <div style={{ display: "flex", flexDirection: "column" }}>
            Status
            <Select
              defaultValue=""
              style={{ width: 170 }}
              onChange={(value) => {
                setStatus(value);
                setSelectedStatus(value);
                onPaginationChange(1, pageSize);
              }}
            >
              <Option value="">ALL</Option>
              <Option value="SUCCEEDED">SUCCEEDED</Option>
              <Option value="FAILED">FAILED</Option>
              <Option value="PROCESSING">PROCESSING</Option>
            </Select>
          </div>
        ),
        dataIndex: "status",
        render: (text: any, value: any) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              justifyContent: "space-between",
            }}
          >
            <div className="status" style={{ color: getClass(value.status) }}>
              <p style={{ color: getClass(value.status) }}>{value.status}</p>
            </div>
          </div>
        ),
        align: "left",
        className: "request-container-table-status",
      },
    ];
    // columns.forEach((column) => {
    //   if (column.sorter) {
    //     column.sortDirections = [];
    //   }
    // });
    return columns.filter((column) => column !== null) as ColumnsType<IDocumentsDataType>;
  };

  useEffect(() => {
    setIsSelectAllChecked(false);
  }, [page, pageSize]);

  useEffect(() => {
    fetchRequests();
  }, [fetchRequests, selectedFromDate, selectedToDate, searchTerm, sort]);

  const onRangeChange = (dates: RangeValue<Moment>, dateStrings: string[]) => {
    if (dates) {
      dispatch(
        setSelectedDateRange({
          fromDate: dates[0]?.toString() ?? null,
          toDate: dates[1]?.toString() ?? null,
        })
      );
    } else {
      dispatch(
        setSelectedDateRange({
          fromDate: defaultFromDate?.toString() ?? null,
          toDate: defaultToDate?.toString() ?? null,
        })
      );
    }
  };

  if (!isAuthorized) {
    return <NotAccessibleFallback />;
  }

  const expandedRowRender = (record: IDocumentsDataType) => {
    return (
      <ExpandedRowContent
        record={record}
        expandedRowKey={expandedRowKey}
        copilotName={copilotName}
      />
    );
  };

  const CustomExpandIcon = ({ expanded, onExpand, record }: any) => {
    const iconStyle = { fontSize: "20px", cursor: "pointer" };
    if (expanded) {
      return (
        <Button style={{ padding: "5px" }} onClick={(e) => onExpand(record, e)}>
          <MinusOutlined style={iconStyle} />
        </Button>
      );
    }
    return (
      <Button style={{ padding: "5px" }} onClick={(e) => onExpand(record, e)}>
        <PlusOutlined style={iconStyle} />
      </Button>
    );
  };

  return (
    <div>
      <div style={{ display: "flex", marginBottom: "2em" }} className="tabHeader">
        <RangePicker
          allowClear={false}
          defaultValue={[
            selectedFromDate ? moment(selectedFromDate) : null,
            selectedToDate ? moment(selectedToDate) : null,
          ]}
          presets={rangePresets}
          onChange={onRangeChange}
          className="range-picker"
          disabledDate={(current) => {
            return current && current.valueOf() > moment().endOf("day").valueOf();
          }}
          disabled={!!searchTerm}
        />
        <div className="tabHeader-delete">
          {selectedRowKeys.length ? (
            <p className="tabHeader-selected">{`${selectedRowKeys.length} ${
              selectedRowKeys.length === 1 ? "item" : "items"
            } selected`}</p>
          ) : (
            <></>
          )}
          <AppPagination
            className="tr m-b paginationDiv"
            {...{
              showSizeChanger: true,
              current: page,
              total: totalDocuments,
              pageSize,
              pageSizeOptions,
              onChange: onPaginationChange,
            }}
          />
        </div>
      </div>
      <Table
        className="request-container-table"
        columns={getColumns()}
        dataSource={dataSource}
        onChange={onTableChange}
        loading={isDocumentsLoading}
        pagination={false}
        expandable={{
          expandedRowRender,
          expandIconColumnIndex: 6,
          columnTitle: "Detailed Logs",
          onExpand: handleExpand,
          expandIcon: CustomExpandIcon,
        }}
        locale={{ emptyText: <Empty>No Documents Found</Empty> }}
      />
    </div>
  );
};

export default Requests;
