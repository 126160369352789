import { Dispatch } from "redux";
import { RootState, closeModal } from "../../../store";
import { MODAL_TYPE } from "../../../utils";
import { connect } from "react-redux";
import FileUploadModal from "./FileUploadModal";
import { ClaimsService } from "../../../api/claims";

type StateProps = {
    visibility: boolean;
    documentId: number;
};

type DispatchProps = {
  handleClose: () => void;
  handleUpload?: (filePath: string, cleanExistingData: boolean, file: any) => Promise<any>; 
};

export type FileUploadModalProps = StateProps & DispatchProps;

const mapStateToProps = (state: RootState): StateProps => ({
  visibility: (state.ui.modal[MODAL_TYPE.UPLOAD_CSV_FILE] || state.ui.modal[MODAL_TYPE.IMPORT_EDIT_CODE_MAPPING]),
  documentId: state.document.documentId,
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  handleClose: () => {
    dispatch(closeModal(MODAL_TYPE.UPLOAD_CSV_FILE));
    dispatch(closeModal(MODAL_TYPE.IMPORT_EDIT_CODE_MAPPING));
  },
 
});

export default connect(mapStateToProps, mapDispatchToProps)(FileUploadModal);