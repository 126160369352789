import { useCallback, useState } from "react";
import {
  EvidenceFileData,
  EvidenceFileResponse,
  EvidencePageResponseInfo,
  evidenceViewerService,
  PegaPageDataSummary,
  SearchOccurrence,
} from "../api";
import {
  AnGFilesSummaryData,
  COPILOTS_NAME,
  STATUS_CODE,
  angTableSampleData,
  openNotificationWithIcon,
} from "../utils";

interface Props {
  fileId: string;
  searchKeywords: string | null;
}

export const useFetchEvidenceViewer = ({ fileId, searchKeywords }: Props) => {
  const [currPage, setCurrPage] = useState(1);
  const [pageLoading, setPageLoading] = useState(true);
  const [searchOccurenceLoading, setSearchOccurenceLoading] = useState(true);
  const [pageData, setPageData] = useState({} as EvidencePageResponseInfo);
  const [highlightsData, setHighlightsData] = useState({});
  const [keywordsData, setKeywordsData] = useState({});
  const [pegaPageData, setPegaPageData] = useState({} as PegaPageDataSummary);
  const [angData, setAngData] = useState([] as any);
  const [fileData, setFileData] = useState({} as EvidenceFileData);
  const [isTiffFile, setIsTiffFile] = useState(false);
  const [occurencesList, setOccurencesList] = useState([] as SearchOccurrence[]);
  const [isAuthorized, setIsAuthorized] = useState(true);

  const fetchFile = useCallback(
    async (copilotName: string) => {
      if ([COPILOTS_NAME.CCA, COPILOTS_NAME.ANG_INSIGHTS, COPILOTS_NAME.UM].includes(copilotName)) {
        // Skip the API call for CCA and ANG
        return;
      }
      try {
        setFileData({ fileName: "", url: "" });
        const { data } = await evidenceViewerService.getFile(fileId, copilotName);
        if (data.fileData) {
          setFileData(data.fileData);
          setIsTiffFile(data.fileData.fileName.split(".").slice(-1)[0].toLowerCase() === "tiff");
        }
      } catch (err: any) {
        if (err.response.status === STATUS_CODE.FORBIDDEN) {
          setIsAuthorized(false);
        } else {
          openNotificationWithIcon("", err?.response?.data?.message, "error");
        }
      }
    },
    [fileId]
  );

  const fetchPage = useCallback(
    async (copilotName?: string) => {
      try {
        setPageLoading(true);
        let data;
        switch (copilotName) {
          case COPILOTS_NAME.ANG_INSIGHTS:
          case COPILOTS_NAME.UM:
          case COPILOTS_NAME.CCA:
            ({ data } = await evidenceViewerService.getAngCCAData(+fileId));
            setAngData(data);
            break;

          case COPILOTS_NAME.PCP:
            data = await evidenceViewerService.getPcpPageData(fileId);
            setPageData(data);
            break;

          default:
            ({ data } = await evidenceViewerService.getPageData(currPage, fileId));
            setPageData(data);
            break;
        }

      } catch (err: any) {
        if (err.response.status === STATUS_CODE.FORBIDDEN) {
          setIsAuthorized(false);
        } else {
          openNotificationWithIcon("", err?.response?.data?.message, "error");
        }
      } finally {
        setPageLoading(false);
      }
    },
    [currPage, fileId]
  );

  const fetchPegaPage = useCallback(
    async (copilotName?: string) => {
      if (copilotName && [COPILOTS_NAME.CCA, COPILOTS_NAME.ANG_INSIGHTS, COPILOTS_NAME.UM].includes(copilotName)) {
        // Skip the API call for CCA and ANG
        return;
      }
      try {
        setPageLoading(true);
        const { data } = await evidenceViewerService.getPegaPageData(fileId);
        setPegaPageData(data);
      } catch (err: any) {
        if (err.response.status === STATUS_CODE.FORBIDDEN) {
          setIsAuthorized(false);
        } else {
          openNotificationWithIcon("", err?.response?.data?.message, "error");
        }
      } finally {
        setPageLoading(false);
      }
    },
    [fileId]
  );

  const fetchSearchOccurences = useCallback(
    async (tileType: string | null) => {
      setCurrPage(1);
      setSearchOccurenceLoading(true);
      const { data } = await evidenceViewerService.getOccurrencesPages(
        fileId,
        searchKeywords,
        tileType
      );
      const { occurencesList: list } = data;
      setOccurencesList(list.sort((a, b) => a.pageNumber - b.pageNumber));
      setSearchOccurenceLoading(false);
    },
    [fileId, searchKeywords]
  );

  const fetchHEDISJsonsData = useCallback(
    async () => {
      try {
        setPageLoading(true);
        const highlightsJSONFile = await evidenceViewerService.getHighlightsJSON(fileId);
        const highlightsRes = await fetch(highlightsJSONFile.data.highlightsJson);
        const highlightsJsonData = await highlightsRes.json();
        setHighlightsData(highlightsJsonData);
        const keywordsJSONFile = await evidenceViewerService.getKeywordsJSON(fileId);
        const keywordsRes = await fetch(keywordsJSONFile.data.keywordsJson);
        const keywordsJsonData = await keywordsRes.json();
        setKeywordsData(keywordsJsonData);
      } catch (err: any) {
        if (err.response.status === STATUS_CODE.FORBIDDEN) {
          setIsAuthorized(false);
        } else {
          openNotificationWithIcon("Error fetching HEDIS JSONs data", err?.response?.data?.message, "error");
        }
      } finally {
        setPageLoading(false);
      }
    },
    [fileId]
  )

  return {
    fetchFile,
    fetchPage,
    fetchPegaPage,
    currPage,
    pageLoading,
    searchOccurenceLoading,
    pageData,
    pegaPageData,
    angData,
    fileData,
    setCurrPage,
    fetchSearchOccurences,
    occurencesList,
    isAuthorized,
    isTiffFile,
    fetchHEDISJsonsData,
    highlightsData,
    keywordsData
  };
};
