import type React from "react";
import { useState, useEffect, useMemo, useRef } from "react";
import { Table, Button, Space, Modal, message, Input, Empty } from "antd";
import { EditOutlined, DeleteOutlined, ReloadOutlined, SearchOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import { AppPagination, Loading } from "../../../../components";
import { useFetchDocuments } from "../../../../hooks";
import { useDispatch, useSelector } from "react-redux";
import { openNotificationWithIcon } from "../../../../utils";
import { ClaimsService } from "../../../../api/claims";
import { openModal, RootState } from "../../../../store";
import { MODAL_TYPE } from "../../../../utils";
import { TableProps } from "antd/lib";
import { SorterResult } from "antd/es/table/interface";
import "./../copilots-details.scss";
import Search from "antd/es/input/Search";

const EditCodeSopMapping: React.FC<any> = ({ copilotName }) => {
  const {
    documents,
    isDocumentsLoading,
    paginationObj,
    fetchEditCodeMapping,
    totalDocuments,
    setSortBy,
    searchTerm,
    setSearchTerm,

  } = useFetchDocuments();
  const { onPaginationChange, page, pageSize, pageSizeOptions } = paginationObj;
  const dispatch = useDispatch();
  const [refreshLoading, setRefreshLoading] = useState(false); 
  const [editingKey, setEditingKey] = useState<string | null>(null);
  const [editCodeValue, setEditCodeValue] = useState<string>("");
  const [documentValue, setDocumentValue] = useState<string>("");
  const searchInputRef = useRef(null as any);
  const [sorterState, setSorterState] = useState() as any;
  const isFileImported = useSelector((state: RootState) => state.ui.isFileImported);
  const [isUpdating, setIsUpdating] = useState(false);
  const handleRefresh = async () => {
    setRefreshLoading(true); 
    try {
      await fetchEditCodeMapping();
    } catch (error) {
      openNotificationWithIcon("Failed to refresh", "Failed to refresh data", "error");
    } finally {
      setRefreshLoading(false); 
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);
  };

  const handleSearch = async () => {
    await fetchEditCodeMapping();
  };

  const onTableChange: TableProps<any>["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {
  
    if (searchInputRef.current && document.activeElement === searchInputRef.current.input) {
      return;
    }
  
    setSorterState(sorter); // Keep track of the sorter state
    const firstSorter = sorter as SorterResult<any>;
  
    const fieldMap: Record<string, string> = {
      document: "name", // Convert `document` to `name`
      editCode: "edit_code", // Convert `editCode` to `edit_code`
    };
    const mappedField = fieldMap[firstSorter.field as string] || firstSorter.field;
  
    // Update the sortBy in the format `field:direction`
    switch (firstSorter.order) {
      case "descend":
        setSortBy(`${mappedField}:desc`); // Format: mappedField:desc
        break;
      case "ascend":
        setSortBy(`${mappedField}:asc`); // Format: mappedField:asc
        break;
      default:
        setSortBy(""); // Clear sortBy when no sorting is applied
        break;
    }
  };
  

  useEffect(() => {

    if(searchTerm===""){
      fetchEditCodeMapping();
    }
  }, [isFileImported, searchTerm,sorterState]);

  const handleEdit = (record: any) => {
    setEditingKey(record.id);
    setEditCodeValue(record.editCode);
    setDocumentValue(record.document);
  };

  const handleCancel = () => {
    setEditingKey(null);
    setEditCodeValue("");
    setDocumentValue("");
  };
  

  const handleSave = async (record: any) => {
    setIsUpdating(true);    
    if (editCodeValue.toString().trim().length === 0 || documentValue.trim().length === 0) {
      return openNotificationWithIcon("", "Value cannot be empty", "error");
    }

    try {
      await ClaimsService.saveEditCodeMapping(record.id, editCodeValue, documentValue);
      openNotificationWithIcon("Updated Successfully", "Mapping updated successfully", "success");
      fetchEditCodeMapping();
    } catch (error) {
        openNotificationWithIcon("Failed to update", "Failed to update mapping", "error");
    } finally {
        setIsUpdating(false);    
        handleCancel();
    }
  };

  const dataSource: any[] = useMemo(
    () =>
      documents.map((document: any) => ({
        id: document.id,
        editCode: document.edit_code,
        document: document.document,
      })),
    [documents]
  );

const handleImportModal = () => {
    dispatch(openModal(MODAL_TYPE.IMPORT_EDIT_CODE_MAPPING));
}

  const handleDelete = (id: number) => {
    Modal.confirm({
      title: "Are you sure you want to delete this mapping?",
      onOk: async () => {
        try {
          await ClaimsService.deleteEditCodeMapping(id);
          openNotificationWithIcon("Record Deleted" ,"Mapping deleted successfully", "success");
          fetchEditCodeMapping();
        } catch (error) {
            openNotificationWithIcon("Failed to delete","Failed to delete mapping", "error");
        }
      },
    });
  };

  const getColumns = (): ColumnsType<any> => {
    const columns = [
    {
      title: "Edit Code",
      dataIndex: "editCode",
      key: "editCode",
      className: "text-wrap-class doc-editcode",
      render: (text: string, record: any) =>
        editingKey === record.id ? (
          <Input
            value={editCodeValue}
            onChange={(e) => setEditCodeValue(e.target.value)}
          />
        ) : (
          text
        ),
        sorter: true,
    },
    {
      title: "Document",
      dataIndex: "document",
      key: "document",
      className: "text-wrap-class doc-name",
      render: (text: string, record: any) =>
        editingKey === record.id ? (
          <Input
            value={documentValue}
            onChange={(e) => setDocumentValue(e.target.value)}
          />
        ) : (
          text
        ),
        sorter: true,
    },
    {
      title: "Action",
      key: "action",
      className: "mapping-action",
      render: (_:string, record: any) => (
        <Space size="middle">
          {editingKey === record.id ? (
            <>
              <Button onClick={() => handleSave(record)} loading={isUpdating}>Save</Button>
              <Button onClick={handleCancel}>Cancel</Button>
            </>
          ) : (
            <Button
              icon={<EditOutlined />}
              onClick={() => handleEdit(record)}
              aria-label={`Edit mapping ${record.id}`}
            >
              Edit
            </Button>
          )}
         {
            ( editingKey !== record.id ) &&
            ( <Button
                icon={<DeleteOutlined />}
                onClick={() => handleDelete(record.id)}
                danger
                aria-label={`Delete mapping ${record.id}`}
            >
                Delete
            </Button>)
            }
        </Space>
      ),
    },
    ];
    
    return columns.filter((column) => column !== null) as ColumnsType<any>;
};

  return (
    <div className="edit-code-sop-mapping">
      <div className="flex jcsb">
       

        <div className="flex gp-10">
        <Search
            className="record-search m-b"
            placeholder="Search"
            enterButton={
              isDocumentsLoading ? <Loading tip="" /> : <Button icon={<SearchOutlined />} />
            }
            value={searchTerm}
            onChange={(e) => handleChange(e)}
            onSearch={(e) => handleSearch()}
            disabled={isDocumentsLoading}
            style={{ width: "20em", marginRight: "1em" }}
          />
            <Button  className="outline" onClick={()=>handleImportModal()}> 
                Import CSV From Blob
            </Button>
          <Button
            className="outline"
            type="default"
            onClick={handleRefresh}
            disabled={refreshLoading} // Disable while refreshing
          >
            Refresh <ReloadOutlined spin={refreshLoading} /> {/* Spin when refreshing */}
          </Button>
         
        </div>
       
        <AppPagination
          className="tr m-b paginationDiv"
          {...{
            showSizeChanger: true,
            current: page,
            total: totalDocuments,
            pageSize,
            pageSizeOptions,
            onChange: onPaginationChange,
          }}
        />
      </div>
      <div className="total-count-container">
        <span className="total-count-label">Total Records :</span>
        <span className="total-count-value">{totalDocuments}</span>
      </div>
        
      <div>
        <Table
            className="document-container-table"
            columns={getColumns()}
            dataSource={dataSource}
            onChange={onTableChange}
            loading={isDocumentsLoading}
            pagination={false}
            locale={{ emptyText: <Empty>No Mapping Data Found</Empty> }}
            />
      </div>
    </div>
  );
};

export default EditCodeSopMapping;
