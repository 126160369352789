export const claimsStatuses = [
  {
    "id": 1,
    "name": "PROCESSING",
  },
  {
    "id": 2,
    "name": "READY",
  },
  {
    "id": 3,
    "name": "FAILED",
  },
  {
    "id": 6,
    "name": "NOT PROCESSED",
  },
  {
    "id": 7,
    "name": "DELETED",
  },
  {
    "id": 8,
    "name": "PROCESSING",
  }
]