import type React from "react"
import { useState, useEffect } from "react"
import { Modal, Button, Input, Form, Select } from "antd"
import { CloseOutlined } from "@ant-design/icons"
import ReactJson from "react-json-view"
import "../modal.scss"
import { claimsStatuses } from "../../../utils/constants/statuses"
import { clearModalData, setIsChanged } from "../../../store"
import { useDispatch } from "react-redux"

interface DocumentEditModalProps {
  visibility: boolean
  handleClose: () => void
  handleSubmit: (document: any, uuid: string) => void
  document: any
}

const DocumentEditModal: React.FC<DocumentEditModalProps> = ({ visibility, handleClose, handleSubmit, document }) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState<boolean>(false)
  const [metadata, setMetadata] = useState<any>(document.metadata)
  const dispatch = useDispatch()

  useEffect(() => {
    form.setFieldsValue({
      ...document,
      status: claimsStatuses.find((status) => status.name === document.status)?.id,
      metadata: document.metadata, // Set metadata in form
    })
    setMetadata(document.metadata)
  }, [document, form])

  const handleModalClose = () => {
    form.resetFields()
    setLoading(false)
    handleClose()
  }

  const handleFormSubmit = async () => {
    try {
      setLoading(true)
      const values = await form.validateFields()
      const statusName = claimsStatuses.find((status) => status.id === values.status)?.name || ""
      await handleSubmit({ ...values, status: statusName, metadata: values.metadata }, document.uuid)
      dispatch(setIsChanged(true))
      handleModalClose()
    } catch (error) {
      console.error("Failed to submit form", error)
    } finally {
      setLoading(false)
    }
  }

  const handleJsonChange = (edit: any) => {
    const updatedMetadata = edit.updated_src
    setMetadata(updatedMetadata)
    form.setFieldsValue({ metadata: updatedMetadata }) // Update form values
  }

  return (
    <Modal
      className="autonomizeModal edit-document-modal"
      centered
      width={800}
      title="Edit Document"
      open={visibility}
      footer={null}
      closeIcon={<CloseOutlined onClick={handleModalClose} />}
    >
      <h1 className="m-b">Edit Document</h1>
      <Form form={form} layout="vertical" className="flex flex-column ">
        <Form.Item name="name" label="Name" required>
          <Input placeholder="Enter name" />
        </Form.Item>
        <Form.Item name="status" label="Status" required>
          <Select placeholder="Select status">
            {claimsStatuses.map((status) => (
              <Select.Option key={status.id} value={status.id}>
                {status.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="metadata" label="Metadata" required>
          <div className="metadata-editor">
            <ReactJson
              src={metadata}
              onEdit={handleJsonChange}
              onAdd={handleJsonChange}
              onDelete={handleJsonChange}
              theme="monokai"
              displayDataTypes={false}
              enableClipboard={false}
              style={{
                padding: "10px",
                borderRadius: "4px",
                maxHeight: "400px",
                overflowY: "auto",
              }}
            />
          </div>
        </Form.Item>
        <div className="flex jcc gp">
          <Button type="primary" className="outline" onClick={handleModalClose}>
            Cancel
          </Button>
          <Button type="primary" className="fill" loading={loading} onClick={handleFormSubmit}>
            {loading ? "Submitting..." : "Submit"}
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

export default DocumentEditModal

