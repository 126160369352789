import React from "react";
import { EvidencePageResponseInfo, PegaPageDataSummary } from "../../../api";
import { EvidenceViewerDefault } from "./EvidenceContentDefault";
import { Collapse, Empty, Table } from "antd";
import { CollapseProps } from "antd/lib";
import { EvidenceContentFormData } from "./EvidenceContentFormData";
import { Loading } from "../../../components";

interface ExtractionOutputProps {
  pageInfo: EvidencePageResponseInfo;
  searchKeywords: string;
  selectedLegends: string[];
  loading: boolean;
  pegaPageInfo?: PegaPageDataSummary;
}

interface Procedure {
  procedureCode: string;
  dateOfServiceStart: string;
  dateOfServiceEnd: string;
}

interface Diagnosis {
  diagnosisCode: string;
  diagnosisQualifier: string;
}

interface ComponentProps {
  data?: any;
}

export const ExtractionOutput = ({
  pageInfo,
  searchKeywords,
  selectedLegends,
  loading,
  pegaPageInfo,
}: ExtractionOutputProps) => {

  console.log(pageInfo);
  console.log(pegaPageInfo)

  const columns = [
    {
      title: "Key",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Values",
      dataIndex: "values",
      key: "values",
      render: (values: string | Procedure[] | Diagnosis[]) => {
        if (Array.isArray(values)) {
          if (values.length && "procedureCode" in values[0]) {
            // Procedures
            return (
              <ul>
                {values.map((procedure, index) => (
                  (procedure as Procedure).procedureCode && (
                    <li key={index}>Procedure Code: {(procedure as Procedure).procedureCode}</li>
                  )
                ))}
              </ul>
            );
          } else if (values.length && "diagnosisCode" in values[0]) {
            // Diagnoses
            return (
              <ul>
                {values.map((diagnosis, index) => (
                  (diagnosis as Diagnosis).diagnosisCode && (
                    <li key={index}>Diagnosis Code: {(diagnosis as Diagnosis).diagnosisCode}</li>
                  )
                ))}
              </ul>
            );
          } else {
            // Other array types - ignore
            return null;
          }
        }
  
        return <p>{values}</p>;
      },
    },
  ];

  const renderContent = (Component: React.ComponentType<any>, data: any) => {
    if (loading) {
      return <Loading />;
    }
  
    if (!data || (Array.isArray(data?.form) && data.form.length === 0)) {
      return <Empty>No Data Found</Empty>;
    }
  
    return <Component formData={data} />;
  };

  const items: CollapseProps["items"] = pegaPageInfo
    ? [
      {
        key: "1",
        label: "Text Content",
        children: (
          <EvidenceViewerDefault {...{ pageInfo, searchKeywords, selectedLegends, loading }} />
        ),
        className: "extractionOutput-",
      },
      {
        key: "2",
        label: "Form Data",
        children: renderContent(EvidenceContentFormData, pageInfo?.form),
      },
      {
        key: "3",
        label: "Keyword Extraction Output",
        children: loading ? (
          <Loading />
        ) : (
          <Table
            className="summaryTable"
            dataSource={(() => {
              try {
                const data = JSON.parse(pegaPageInfo?.KeywordExtractionOutput);
                return Array.isArray(data)
                  ? data.map((item) => {
                      if (item.key === "svm_inpatient_classifier_extraction_result") {
                        item.values = JSON.stringify(item.values, null, 2); 
                      }
                      return item;
                    }).sort((a, b) => a.key.localeCompare(b.key))
                  : [];
              } catch (error) {
                console.error("Invalid JSON format for KeywordExtractionOutput", error);
                return [];
              }
            })()}
            columns={columns}
            pagination={false}
            locale={{emptyText: <Empty>No Keywords Found</Empty>}}
          />
        ),
      },
      {
        key: "4",
        label: "Tolstoy Extraction Output",
        children: loading ? (
          <Loading />
        ) : (
          <Table
            className="summaryTable"
            dataSource={(() => {
              try {
                const data = JSON.parse(pegaPageInfo?.TolstoyExtractionOutput);
                return Array.isArray(data)
                  ? data.sort((a, b) => a.key.localeCompare(b.key))
                  : [];
              } catch (error) {
                console.error("Invalid JSON format for TolstoyExtractionOutput", error);
                return [];
              }
            })()}
            columns={columns}
            pagination={false}
            locale={{emptyText: <Empty>No Data Found</Empty>}}

          />
        ),
      },
      ]
    : [
        {
          key: "1",
          label: "Text Content",
          children: (
            <EvidenceViewerDefault {...{ pageInfo, searchKeywords, selectedLegends, loading }} />
          ),
          className: "extractionOutput-",
        },
        {
          key: "2",
          label: "Form Data",
          children: loading ? <Loading /> : <EvidenceContentFormData formData={pageInfo.form} />,
        },
      ];
  return (
    <div>
      {/* <h3 style={{marginBottom: "20px"}}>Cognitive Search</h3> */}
      <Collapse style={{ marginBottom: "20px" }} items={items} />
    </div>
  );
};
