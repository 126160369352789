import React, { useState } from "react";
import { Button, Input } from "antd";
import { Copilots, MODAL_TYPE, PAGE_URL, servicesHealth } from "../../utils";
import { ApplicationCard } from "../applications/applications-container";
import { MonitoringContainer, ServiceStatusContainer } from "../";
import { SummaryDataAndGraphs } from "../summary-data";

export const CopilotsDashboardContainer = () => {
  const activeCopilots = Copilots;
  
  return (
    <>
      <div className="flex gp aic">
        <h3 className="service-status-title">AI Copilots</h3>
        <div>|</div>
        <div>
          <span className="service-status-number">8</span> Copilots Deployed
        </div>
        <div>
          <span className="service-status-number">8</span> Active Copilots Running
        </div>
      </div>
      <div className="m-t application-card-grid">
        <div className="task-card-grid">
          {activeCopilots.map((application, index) => (
            <ApplicationCard application={application} identifier={index} key={index} />
          ))}
        </div>
      </div>
      <div style={{ marginTop: "3em" }}>
        <SummaryDataAndGraphs overallSummary={true} />
        {/* <ServiceStatusContainer isDashboard services={servicesHealth.data as unknown as IService[]} /> */}
        <MonitoringContainer />
      </div>
    </>
  );
};
