import React, { useEffect, useState } from "react";
import "antd/dist/reset.css";
import { MyData } from "../../pages/CopilotDetails";
import { Copilots, IApplication, appendTabToRouteQuery } from "../../utils";
import "./copilot-details-container.scss";
import { getCopilotDetailsTabs, COPILOT_DETAILS_TABS,COPILOT_CCA_TAB, COPILOT_PCP_TAB, COPILOT_DETAILS_TAB_CLAIMS } from ".";
import CopilotsDetailsTab from "./copilots-details-container/CopilotsDetailsTab";
import ServiceStatus from "./copilots-details-container/ServiceStatus";
import ProcessingReport from "./copilots-details-container/ProcessingReport";
import Documents from "./copilots-details-container/Documents";
import Requests from "./copilots-details-container/Requests";
import Utilization from "./copilots-details-container/Utilization";
import QAHistory from "./copilots-details-container/QAHistory";
import QAFeedback from "./copilots-details-container/QAFeedback";
import Keywords from "./copilots-details-container/keywords/Keywords";
import { useParams } from "react-router-dom";
import {TokenUtilization} from "./copilots-details-container/TokenUtilization";
import SearchLogs from "./copilots-details-container/claims/SearchLogs";
import ClaimsData from "./copilots-details-container/claims/ClaimsData";
import ClaimsDocuments from "./copilots-details-container/claims/ClaimsDocuments";
import ClaimsSettings from "./copilots-details-container/claims/ClaimsSettings";
import EditCodeSopMapping from "./copilots-details-container/claims/EditCodeSopMapping";

interface CopilotDetailsContainerProps {
  copilotInfo: MyData | undefined;
}

const CopilotDetailsContainer = (props: CopilotDetailsContainerProps) => {
  const COPILOT_DETAILS_TAB = getCopilotDetailsTabs(
    props.copilotInfo?.application?.title ? props.copilotInfo?.application?.title : ""
  );
  const queryParams = new URLSearchParams(location.search);
  const routeTab = queryParams.get("tab");
  const defaultTab = routeTab || COPILOT_DETAILS_TAB.SERVICE_STATUS;

  const [selectedTab, setSelectedTab] = useState(defaultTab);
  const [component, setComponent] = useState(<></>);
  const { copilotId } = useParams();

  const application = Copilots.find((app: IApplication) => `${app.id}` === copilotId);

  const onChange = (key: string) => {
    setSelectedTab(key);
  };
  useEffect(() => {
    let res = <></>;
    switch (selectedTab) {
      case COPILOT_DETAILS_TABS.SERVICE_STATUS:
        res = (
          <ServiceStatus
            application={application}
            copilotName={
              props.copilotInfo?.application?.title ? props.copilotInfo?.application?.title : ""
            }
          />
        );
        break;
      case COPILOT_DETAILS_TABS.PROCESSING_REPORT:
        res = <ProcessingReport />;
        break;
      case COPILOT_DETAILS_TABS.DOCUMENTS:
        res = (
          <Documents
            copilotName={
              props.copilotInfo?.application?.title ? props.copilotInfo?.application?.title : ""
            }
          />
        );
        break;
      case COPILOT_DETAILS_TABS.UTILIZATION:
        // if(![5, 7].includes(application?.id ?? -1)) {
        //   res = <Utilization application={props.copilotInfo?.application} />
        // }else{
          res = <TokenUtilization
          copilotName={
            props.copilotInfo?.application?.title ? props.copilotInfo?.application?.title : ""
          }
        />
        // }
        break;
      case COPILOT_DETAILS_TABS.QA:
        res = <QAHistory />;
        break;
      case COPILOT_DETAILS_TABS.FEEDBACKS:
        res = <QAFeedback />;
        break;
      case COPILOT_DETAILS_TABS.KEYWORDS:
        res = <Keywords />;
        break;
      case COPILOT_CCA_TAB.SERVICE_STATUS:
        res = (
          <ServiceStatus
            application={application}
            copilotName={
              props.copilotInfo?.application?.title ? props.copilotInfo?.application?.title : ""
            }
          />
        );
        break;
      case COPILOT_CCA_TAB.DOCUMENTS:
        res = (
          <Requests
            copilotName={
              props.copilotInfo?.application?.title ? props.copilotInfo?.application?.title : ""
            }
          />
        );
        break;
      case COPILOT_CCA_TAB.TOKENUTILIZATION:
        res = (
          <TokenUtilization
            copilotName={
              props.copilotInfo?.application?.title || ""
            }
          />
        );
        break;
      case COPILOT_DETAILS_TAB_CLAIMS.SEARCH_LOGS:
        res = (
          <SearchLogs  
            copilotName={props.copilotInfo?.application?.title ? props.copilotInfo?.application?.title : ""}
          />
        )
        break;
      case COPILOT_DETAILS_TAB_CLAIMS.CLAIMS_DATA:
        res = (
          <ClaimsData  
            copilotName={props.copilotInfo?.application?.title ? props.copilotInfo?.application?.title : ""}
          />
        )
        break;
      case COPILOT_DETAILS_TAB_CLAIMS.DOCUMENTS:
          res = (
            <ClaimsDocuments  
              copilotName={props.copilotInfo?.application?.title ? props.copilotInfo?.application?.title : ""}
            />
          )
          break;
      case COPILOT_DETAILS_TAB_CLAIMS.SETTINGS:
        res = (
          <ClaimsSettings
          />
        )
        break;
      case COPILOT_DETAILS_TAB_CLAIMS.EDIT_CODE_SOP_MAPPING:
        res = (
          <EditCodeSopMapping 
            copilotName={props.copilotInfo?.application?.title ? props.copilotInfo?.application?.title : ""}
          />
        )
    }
    setComponent(res);
    appendTabToRouteQuery(selectedTab);
  }, [selectedTab, application]);

  return (
    <>
      <div className="project-heading">
        <p className="project-heading-text">{application?.title}</p>
        <CopilotsDetailsTab {...{ onChange, selectedTab, application }} />
      </div>
      <div className="project-tab-content">{component}</div>
    </>
  );
};

export default CopilotDetailsContainer;
